<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundColor: '#133053' }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">Alertas</span>
        <!-- <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          23 new
        </span> -->
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
             
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Grupales
          </a>
        </li> -->
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in notificationsList">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-5"
                v-bind:class="`symbol-light-${item.status == 1 ? 'success' : 'danger'}`"
              >
                <span class="symbol-label">
                  <b-icon icon="bell-fill" :variant="item.status == 1 ? 'success' : 'danger'"></b-icon>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  class="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  {{ item.created_at.substring(0,16) }}
                </a>
                <span class="text-muted">
                  {{ item.subject }}
                </span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in list2">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i v-bind:class="item.icon"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.title }}
                  </div>
                  <div class="text-muted">
                    {{ item.desc }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new notifications.
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";

import { GET_NOTIFICATIONS } from "@/core/services/store/cvtas.module";


export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      notificationsList: [],
      list1: [
      ],
      list2: [
        {
          title: "En Desarrollo",
          desc: "",
          icon: "flaticon2-line-chart text-success"
        },
      ]
    };
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    fetchNotifications(){
      this.$store.dispatch(GET_NOTIFICATIONS, this.currentUser.email).then((response) => {
          // console.log('notificaciones : '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          try {
            this.notificationsList = JSON.parse(response).notification;
          } catch (error) {
            
          }
      }).catch((err) => {
        this.notificationsList = [];
        console.log(err)
      });
    }
  },
  beforeMount(){
  },
  mounted(){
    this.fetchNotifications();
  },
  computed: {
     ...mapGetters(["currentUser"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>
